import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
} from 'react';
import { FaMediumM, FaMobileAlt } from 'react-icons/fa';
import {
  RiComputerLine,
  RiGroupLine,
  RiLinkedinFill,
  RiPagesLine,
} from 'react-icons/ri';
import { MdComputer } from 'react-icons/md';
import { FiInstagram, FiLayers } from 'react-icons/fi';
import { CgShoppingBag } from 'react-icons/cg';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import {
  IoIosArrowForward,
  IoIosArrowUp,
  IoIosCall,
  IoLogoWhatsapp,
  IoMdMail,
} from 'react-icons/io';
import { AiFillInstagram } from 'react-icons/ai';
import { BsCardText, BsWhatsapp } from 'react-icons/bs';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Hero,
  AboutUs,
  OurServices,
  Box,
  // Portfolio,
  Experience,
  Contact,
  Footer,
} from './styles';
import Devices from '~/components/Devices';
// import Carousel from '~/components/Carousel';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputMask from '~/components/InputMask';

import logo from '~/assets/logos/full-logo.svg';
import logoWhite from '~/assets/logos/full-logo-white.svg';
import computer from '~/assets/banners/computer.png';
import arrow from '~/assets/icons/arrow.svg';
import experience from '~/assets/defaults/experience.png';
import contact from '~/assets/defaults/contact.png';
import desktop from '~/assets/defaults/desktop.svg';
import mobile from '~/assets/defaults/mobile.svg';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';
import InputCheckbox from '~/components/InputCheckbox';

interface IFormData {
  name: string;
  email: string;
  celphone: string;
  message: string;
  accept: boolean;
  robot: string;
}

const Home: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const [infoDeviceTitle, setInfoDeviceTitle] = useState('');
  const [infoDevice, setInfoDevice] = useState('');
  const [returnTopActive, setReturnTopActive] = useState(false);
  const [width, setWidth] = useState(0);
  const [accept, setAccept] = useState(false);
  const [acceptError, setAcceptError] = useState('');
  const [celphoneError, setCelphoneError] = useState('');

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  const handleScroll = useCallback(() => {
    const position = window.pageYOffset;
    if (aboutUsRef.current) {
      setReturnTopActive(position >= aboutUsRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const handleMouseEnter = useCallback(async (value) => {
    if (value === 'Desktop') {
      setInfoDeviceTitle('Sites e Páginas');
      setInfoDevice(
        'Com as melhores tecnologias do mercado, e com boas práticas de desenvolvimento, nossos sites são designados para alta performance para seu cliente e com a jornada exclusiva para que tenha a melhor\xa0experiência.'
      );
    }

    if (value === 'Mobile') {
      setInfoDeviceTitle('Aplicativos');
      setInfoDevice(
        'Com aplicativos responsivos, para qualquer dispositivo que seu usuário utiliza, desenvolvemos a melhor experiência, e com alta performance para você e seu\xa0cliente.'
      );
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    setInfoDeviceTitle('');
    setInfoDevice('');
  }, []);

  const handleChangeAccept = useCallback((values) => {
    setAccept(values[0].selected);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      formRef.current?.setErrors({});
      setAcceptError('');
      setCelphoneError('');
      try {
        if (!data.robot) {
          const schema = Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            email: Yup.string()
              .email('Informe um e-mail válido')
              .required('O e-mail é obrigatório'),
            celphone: Yup.string().required('O celular é obrigatório'),
            message: Yup.string().required('A mensagem é obrigatório'),
            accept: Yup.string().when('$accept', {
              is: (acceptCheck: boolean) => acceptCheck,
              then: Yup.string().required('O aceite é obrigatório'),
              otherwise: Yup.string(),
            }),
            celphoneData: Yup.string().when('$celphoneData', {
              is: (celphoneDataCheck: boolean) => celphoneDataCheck,
              then: Yup.string(),
              otherwise: Yup.string().required('Informe um número válido'),
            }),
          });

          await schema.validate(data, {
            abortEarly: false,
            context: {
              accept: !accept,
              celphoneData:
                data.celphone &&
                (data.celphone.length === 14 || data.celphone.length === 15) &&
                !data.celphone.includes('(55)'),
            },
          });

          const formData = {
            name: data.name,
            email: data.email,
            celphone: data.celphone,
            message: data.message,
            accept: true,
          };

          await api.post('leads', formData);
          setAccept(false);
          formRef.current?.reset();
        }

        history.push(`${process.env.PUBLIC_URL}/solicitacao-recebida`);
        Swal.fire('Tudo certo!', 'Contato salvo com sucesso.', 'success').then(
          () => {
            history.push(`${process.env.PUBLIC_URL}`);
          }
        );
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.accept) {
            setAcceptError(errors.accept);
          }
          if (errors.celphoneData && !errors.celphone) {
            setCelphoneError(errors.celphoneData);
          }
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire('Opss...', 'Ocorreu um erro, tente novamente.', 'error');
        }
      }
    },
    [accept, history]
  );

  return (
    <Container returnTopActive={returnTopActive}>
      <Hero id="hero" className="pt-5 py-lg-5 container-xxl">
        <div className="container h-100 container-xxl-fluid">
          <div className="row h-100 justify-content-center align-items-center align-content-between">
            <div className="col-12">
              <img
                src={logo}
                alt="logo"
                className="w-75 w-lg-auto"
                title="Devsigner Systems"
              />
            </div>
            <div className="col-lg-6 order-1 order-lg-0 my-5 my-lg-0">
              <div className="py-xl-5 my-lg-5">
                <h1 className="display-xl-4 pt-lg-5 pb-3 text-center text-lg-start">
                  <small className="h5 h4-lg fw-normal d-flex justify-content-center justify-content-lg-start text-secondary">
                    Vamos construir, juntos
                  </small>
                  <span className="fw-semibold">
                    Sua grande <b className="text-primary fw-semibold">ideia</b>{' '}
                    começa&nbsp;aqui
                  </span>
                </h1>
              </div>
              <img
                src={arrow}
                alt="arrow"
                className="d-block w-25 w-lg-auto mx-auto mx-lg-unset mb-3 mb-lg-0"
                title="Seta giratória"
              />
            </div>
            <div className="col-lg-6 order-0 order-lg-1 my-4 my-lg-0">
              <img
                src={computer}
                alt="computer"
                className="w-100"
                title="Computador"
              />
            </div>
            <div className="col-lg-3 col-xl-2 d-flex justify-content-center align-items-center order-1 order-lg-1">
              <AnchorLink
                href="#aboutUs"
                className="border-0 bg-transparent d-flex flex-column justify-content-center align-items-center"
                title="Sobre Nós"
              >
                Scroll para ver mais <span className="mt-2">|</span>
              </AnchorLink>
            </div>
          </div>
        </div>
      </Hero>
      <AboutUs
        ref={aboutUsRef}
        id="aboutUs"
        className="mt-5 pt-4 position-relative overflow-hidden"
      >
        <div className="mt-4">
          <div className="container position-relative">
            <div className="row justify-content-center align-items-center">
              <div className="col-lg-5 col-xl-4 mb-lg-3 position-lg-relative">
                <div className="py-5 px-3 px-lg-4 px-xxl-5">
                  <img
                    src={logoWhite}
                    alt="logo"
                    className="mb-5 d-none d-lg-block"
                    title="Devsigner Systems"
                  />
                  <h2 className="text-white fw-semibold">
                    Saiba mais sobre nossos serviços
                  </h2>
                </div>
                <p className="position-absolute company-name fw-semibold">
                  Devsigner
                </p>
              </div>
              <span className="d-none d-lg-block position-relative" />
              <div className="col-lg-5 col-xl-4 pb-5 pb-lg-0">
                <div className="py-5 px-3 px-lg-5">
                  <div className="detail mb-4" />
                  <p className="text-white">
                    Encaramos cada projeto como uma <b>nova parceria</b> e nos
                    certificamos de <b>facilitar todos os processos</b>, mesmo
                    quando enfrentamos os problemas mais&nbsp;complexos.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AboutUs>
      <OurServices className="position-relative">
        <div className="container py-5">
          <div className="row justify-content-between align-items-center pt-5">
            {width >= 992 ? (
              <>
                <div className="col-lg-7">
                  <Devices
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  />
                </div>
                <div className="col-lg-4">
                  {infoDeviceTitle.length > 0 && infoDevice.length > 0 ? (
                    <>
                      <h3 className="text-end text-white mb-4 fw-semibold">
                        {infoDeviceTitle}
                      </h3>
                      <p className="text-end text-white">{infoDevice}</p>
                    </>
                  ) : (
                    <>
                      <div className="detail mb-4 ms-auto" />
                      <h3 className="text-end text-white mb-4 fw-semibold">
                        Nossos serviços
                      </h3>
                      <p className="text-end text-white">
                        Tenha projetos personalizados e funcionais para seus
                        usuários.
                      </p>
                      <p className="text-end mt-4 text-light-blue d-flex align-items-center justify-content-end">
                        <span className="me-2">|</span> Hover nos dispositivos
                      </p>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col-12">
                  <img
                    src={desktop}
                    alt="desktop"
                    className="w-100 mb-5"
                    title="Desenvolvimento de projetos web"
                  />
                  <div className="detail mx-auto" />
                  <h3 className="text-white text-center fw-semibold my-4">
                    Sites e Páginas
                  </h3>
                  <p className="text-white text-center">
                    Com as melhores tecnologias do mercado, e com boas práticas
                    de desenvolvimento, nossos sites são designados para alta
                    performance para seu cliente e com a jornada exclusiva para
                    que tenha a melhor experiência.
                  </p>
                </div>
                <div className="col-12">
                  <img
                    src={mobile}
                    alt="mobile"
                    className="d-block w-75 mx-auto my-5"
                    title="Desenvolvimento para apps"
                  />
                  <div className="detail mx-auto" />
                  <h3 className="text-white text-center fw-semibold my-4">
                    Aplicativos
                  </h3>
                  <p className="text-white text-center">
                    Com aplicativos responsivos, para qualquer dispositivo que
                    seu usuário utiliza, desenvolvemos a melhor experiência, e
                    com alta performance para você e seu cliente.
                  </p>
                </div>
              </>
            )}
          </div>
          <div className="row align-items-center pb-5">
            <div className="col-12 mt-5 pt-5 mb-4">
              <div className="d-none d-lg-block detail mb-4" />
              <h3 className="text-white mb-4 fw-semibold">Trabalhamos com:</h3>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <FaMobileAlt size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    Aplicativos
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <RiComputerLine size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    Sistemas
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <RiPagesLine size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    Landpages
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <MdComputer size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    Sites
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <RiGroupLine size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    UI | UX Design
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <FiInstagram size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    Social Media
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <FiLayers size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    Digital Design
                  </p>
                </div>
              </Box>
            </div>
            <div className="col-6 col-md-4 col-lg-3 mb-4 mb-lg-5">
              <Box>
                <div className="d-flex flex-column align-items-center justify-content-center py-4">
                  <CgShoppingBag size={40} color="#0075FF" />
                  <p className="h6 h5-lg text-center text-white mb-0 mt-4">
                    E-commerce
                  </p>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </OurServices>
      {/* <Portfolio>
        <div className="container py-5">
          <div className="row py-5">
            <div className="col-12">
              <div className="detail mb-4" />
              <h2 className="fw-normal h5 text-secondary">Portifólio</h2>
              <h3 className="mb-4 fw-semibold">Conheça Nossos Projetos</h3>
              <Carousel className="mt-5" />
            </div>
          </div>
        </div>
      </Portfolio> */}
      <Experience>
        <div className="container py-5">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="detail-h mb-4" />
              <h2 className="mb-5 pb-4 w-75 pe-xl-5">
                Aprendemos com cada <b>experiência</b>
              </h2>
              <div className="d-flex align-items-center pe-lg-5 describe">
                <div className="detail first" />
                <p className="mb-0 ms-3">
                  Somos uma empresa de tecnologia visando sempre o melhor para
                  seu projeto! Desenvolvemos sites e aplicativos de forma única
                  e particular. Trabalhamos para que seu projeto seja
                  personalizado e original.
                </p>
              </div>
              <div className="d-flex align-items-center pe-lg-5 describe">
                <div className="detail second" />
                <p className="mb-0 ms-3">
                  Aprendemos com cada experiência, elevando os limites além do
                  comum. Vamos simplificar e te auxiliar em todos os processos.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center mt-5 mt-lg-0">
              <img
                src={experience}
                alt="experience"
                className="w-75 w-lg-50"
                title="Aprendemos com cada experiência"
              />
              <p className="display-3 text-center fw-semibold outline-blue-text">
                Future
              </p>
            </div>
          </div>
        </div>
      </Experience>
      <Contact className="position-relative overflow-hidden py-lg-5">
        <div className="container py-5">
          <div className="row align-items-center">
            <div className="col-lg-6 mb-5 mb-lg-0">
              <h2 className="fw-normal mb-4">
                Vamos <br />
                construir, <b className="fw-semibold">juntos.</b>
              </h2>
              <h3 className="h6 fw-normal">
                Contate-nos, iremos tirar sua ideia&nbsp;do papel.
              </h3>
              <img
                src={contact}
                alt="contact"
                className="mt-5 d-none d-lg-block"
                title="Contato"
              />
            </div>
            <div className="col-lg-6 mt-4">
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                className="w-lg-75 mx-auto"
              >
                <label className="d-block mb-3">
                  <span>Nome</span>
                  <Input name="name" />
                </label>
                <label className="d-block mb-3">
                  <span>E-mail</span>
                  <Input type="email" name="email" />
                </label>
                <label className="d-block mb-3">
                  <span>Celular</span>
                  <InputMask kind="cel-phone" name="celphone" />
                  {celphoneError && (
                    <span className="small text-danger error">
                      {celphoneError}
                    </span>
                  )}
                </label>
                <label className="d-block mb-3">
                  <span>Mensagem (Opcional)</span>
                  <Textarea name="message" />
                </label>
                <Input name="robot" className="d-none" />
                <button type="submit" className="btn btn-primary w-100 mt-3">
                  Enviar <IoIosArrowForward size={24} color="#fff" />
                </button>
                <InputCheckbox
                  name="accept"
                  options={[
                    {
                      label:
                        'Aceito compartilhar meus dados afim de receber contato da empresa.',
                      value: 1,
                    },
                  ]}
                  className="input-checkbox mt-3"
                  error={acceptError}
                  onChange={handleChangeAccept}
                />
              </Form>
            </div>
          </div>
        </div>
      </Contact>
      <Footer>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4 col-xl-6">
              <img
                src={logo}
                alt="Logo"
                className="mb-3"
                title="Devsigner Systems"
              />
              <p className="mb-0">
                <BsCardText size={24} color="#0075ff" className="me-2" /> CNPJ:
                46.072.972/0001-92
              </p>
            </div>
            <div className="col-lg-4 col-xl-3 my-5 my-lg-0">
              <h4 className="mb-3">Contato</h4>
              <a
                href="tel:+5511913096161"
                className="d-flex mb-3"
                title="Contato Telefone"
              >
                <IoIosCall size={24} color="#0075f5" className="me-2" />
                <span>(11) 91309-6161</span>
              </a>
              <a
                href="mailto:contato@devsigner.com.br"
                className="d-flex"
                title="Contato E-mail"
              >
                <IoMdMail size={24} color="#0075f5" className="me-2" />
                <span>contato@devsigner.com.br</span>
              </a>
            </div>
            <div className="col-lg-4 col-xl-3">
              <h4 className="mb-3">Social</h4>
              <div className="d-flex aling-items-center social-media">
                <a
                  href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20um%20projeto."
                  target="blank"
                  title="Whatsapp"
                >
                  <IoLogoWhatsapp size={24} color="#0075ff" />
                </a>
                <a
                  href="https://www.linkedin.com/company/devsigner-systems/about/"
                  target="blank"
                  title="LinkedIn"
                >
                  <RiLinkedinFill size={24} color="#0075ff" />
                </a>
                <a
                  href="https://www.instagram.com/_devsigner/"
                  target="blank"
                  title="Instagram"
                >
                  <AiFillInstagram size={24} color="#0075ff" />
                </a>
                <a
                  href="https://medium.com/@DevsignerSystems"
                  target="blank"
                  title="Medium"
                >
                  <FaMediumM size={24} color="#0075ff" />
                </a>
                {/* <a href="https://www.linkedin.com/company/devsigner-systems/about/">
                  <SiFacebook size={24} color="#0075ff" />
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </Footer>
      <AnchorLink
        href="#hero"
        className="position-fixed return-top"
        title="Início"
      >
        <IoIosArrowUp size={24} color="#fff" />
      </AnchorLink>
      <a
        href="https://api.whatsapp.com/send?phone=5511913096161&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20um%20projeto."
        target="blank"
        title="Whatsapp"
        className="position-fixed btn-wpp"
      >
        <BsWhatsapp size={50} color="#fff" />
      </a>
    </Container>
  );
};

export default Home;
