import styled from 'styled-components';

export const Container = styled.svg`
  .dark-gray {
    fill: #2c2c2c;
    transition-duration: 0.3s;

    :hover {
      fill: #383838;
    }
  }
`;
