import { darken } from 'polished';
import styled from 'styled-components';

interface IContainer {
  returnTopActive: boolean;
}

export const Container = styled.div<IContainer>`
  .return-top {
    transition-duration: 0.3s;
    opacity: ${(props) => (props.returnTopActive ? 1 : 0)};
    visibility: ${(props) => (props.returnTopActive ? 'visible' : 'hidden')};
    background: #0075ff;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    right: 20px;
    bottom: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-wpp {
    transition-duration: 0.3s;
    background: #29d26e;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      background: ${darken(0.03, '#29d26e')};
    }
  }
`;

export const Hero = styled.div`
  min-height: 100vh;

  a {
    transition-duration: 0.3s;
    bottom: 50px;

    > span {
      border: 1px solid #0075ff;
      color: #0075ff;
      border-radius: 20px;
      height: 36px;
      width: 23px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.3s;
    }

    :hover {
      color: ${darken(0.05, '#202020')};

      > span {
        background: #0075ff !important;
        color: #fff;
      }
    }
  }

  @media screen and (min-width: 992px) {
    height: 100vh;
  }
`;

export const AboutUs = styled.div`
  background-color: #1565c0;

  > div {
    border-top: 1px solid #3588e7;

    .company-name {
      color: #2c74c6;
      left: 0;
      bottom: -3.5rem !important;
      font-size: 6.5rem;
    }

    span {
      width: 1px;
      height: 450px;
      background-color: #3588e7;
      padding: 0;
      opacity: 1 !important;
      margin: 0;
      display: block;

      :after {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: -3px;
        left: -2px;
      }
    }

    .detail {
      width: 62.95px;
      height: 7px;
      background: #bfddff;
      border-radius: 14px;
    }
  }

  @media screen and (min-width: 992px) {
    > div {
      .company-name {
        left: unset;
        right: 5px;
        bottom: -9.7rem !important;
        font-size: 6rem;
      }
    }
  }

  @media screen and (min-width: 1200px) {
    > div {
      .company-name {
        right: 10px;
        bottom: -10rem !important;
        font-size: 7rem;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    > div {
      .company-name {
        right: 20px;
        bottom: -10rem !important;
        font-size: 8rem;
      }
    }
  }
`;

export const OurServices = styled.div`
  background: #2c2c2c;

  .detail {
    width: 62.95px;
    height: 7px;
    background: #0075ff;
    border-radius: 14px;
  }

  .text-light-blue {
    color: #56b0f6;

    > span {
      border: 1px solid #56b0f6;
      border-radius: 20px;
      height: 36px;
      width: 23px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      transition-duration: 0.3s;
    }
  }
`;

export const Box = styled.div`
  width: 100%;
  border-radius: 16px;
  background: rgb(144, 144, 144);
  background: linear-gradient(
    180deg,
    rgba(144, 144, 144, 1) 0%,
    rgba(214, 214, 214, 0.14) 100%
  );
  box-sizing: border-box;
  overflow: hidden;
  padding: 1px;

  > div {
    background: linear-gradient(180deg, #303030 0%, #2f2f2f 100%);
    border-radius: 16px;
  }
`;

export const Portfolio = styled.div`
  .detail {
    width: 62.95px;
    height: 7px;
    background: #0075ff;
    border-radius: 14px;
  }
`;

export const Experience = styled.div`
  .detail-h {
    width: 62.95px;
    height: 7px;
    background: #0075ff;
    border-radius: 14px;
  }

  .describe {
    .detail {
      background: #4e9fff;
      border-radius: 13px;
      width: 4px;
    }

    .first {
      height: 100px;
    }

    .second {
      height: 76px;
    }

    > p {
      width: calc(100% - 20px);
    }

    + .describe {
      margin-top: 34px;
    }
  }

  .outline-blue-text {
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #9dcdfc;
    -webkit-text-fill-color: transparent;
  }

  @media screen and (min-width: 992px) {
    .outline-blue-text {
      font-size: 7rem;
    }
  }
`;

export const Contact = styled.div`
  :before {
    content: '';
    width: 100%;
    height: 210px;
    background: #f0f7ff;
    border-radius: 0px 0px 20px 20px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  form {
    label {
      span {
        font-size: 14px;
        margin-bottom: 6px;
        display: block;
      }
    }

    .input-checkbox {
      justify-content: flex-start;

      label {
        align-items: flex-start;

        div {
          width: 14px;
          height: 14px;
        }

        span {
          font-size: 12px !important;
          margin-top: -1px;
          margin-bottom: 0;
        }
      }
    }

    button {
      height: 46px;
    }
  }

  @media screen and (min-width: 992px) {
    :before {
      width: 50%;
      height: 100%;
      border-radius: 0px 20px 20px 0px;
    }

    :after {
      content: 'Devsigner';
      color: #f9f9f9;
      transform: rotate(-90deg);
      font-size: 11.2rem;
      position: absolute;
      top: 9rem;
      right: -27.5rem;
      font-weight: 600;
    }
  }
`;

export const Footer = styled.div`
  .social-media {
    a + a {
      margin-left: 17px;
    }
  }
`;
